import React from 'react'
import html from '../assets/html.png';
import css from '../assets/css.png';
import javascript from '../assets/javascript.png';
import react  from '../assets/react.png';
import node from '../assets/node.png';
import php from '../assets/php.png';
import git from '../assets/git.png';
import laravel from '../assets/laravel.png';







const Skills = () => {
  const techs = [
    {
      id: 1,
      src: html,
      title: "HTML",
      style: "shadow-xs shadow-html",
    },
    {
      id: 2 ,
      src: css,
      title: "CSS",
      style: "shadow-xs shadow-css",
    },
    {
      id:3 ,
      src: javascript,
      title: "JAVASCRIPT",
      style: "shadow-xs shadow-javascript",
    },
    {
      id: 4,
      src: node,
      title: "NODE",
      style: "shadow-xs shadow-node",
    },
    {
      id: 5,
      src: php,
      title: "PHP",
      style: "shadow-xs shadow-php",
    },
    {
      id: 6,
      src: react,
      title: "REACT",
      style: "shadow-xs shadow-react",
    },
    {
      id: 7,
      src: laravel,
      title: "LARAVEL",
      style: "shadow-xs shadow-laravel",
    },
    {
      id: 8,
      src: git,
      title: "GIT",
      style: "shadow-xs shadow-git",
    },
    
  ];
  return (
    <div name='Skills' className='md:h-screen w-full bg-gradient-to-b from-blackPrimary to to-blackPrimary text-white'>
        <div className=" p-4 mx-auto flex flex-col  ">
          <div className='pt-20'>
            <p className="text-4xl font-bold text-testFluo">
              Skills
            </p>
            <p className="py-6 text-lg md:text-xl font-semibold text-gray1 ">These are the technologies I've worked with</p>
          </div>

          <div className="w-auto grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8 px-2 sm:px-0">
            {techs.map(({ id, src, title, style }) => (
              <div
                key={id}
                className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
              >
              <img src={src} alt='' className="w-20 mx-auto" />
                <p className="mt-4 font-semibold text-gray1">{title}</p>
              </div>
            ))}
          </div>
        </div>
    </div>
  )
}

export default Skills