import React from 'react';
import {FaGithub, FaLinkedin} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi';
import {BsFileEarmarkPerson} from 'react-icons/bs';

const Links = () => {

  const medios = [
    {
      id:1,
      child:(
        <>      
         Linkedin <FaLinkedin size={30}/>
        </>
      ),
      href:'https://www.linkedin.com/in/francisco-galarza/',
    },
    {
      id:2,
      child:(
        <>      
         GitHub <FaGithub size={30}/>
        </>
      ),
      href:'https://github.com/Galarza-Francisco',
    },
    {
      id:3,
      child:(
        <>      
         Mail <HiOutlineMail size={30}/>
        </>
      ),
      href:'mailto:galarzafrancisco98@gmail.com',
    },
    {
      id:4,
      child:(
        <>
        CV <BsFileEarmarkPerson size={30}/>
        </>
      ),
      href:'Curriculum-Vitae.pdf',
      download:'Curriculum-Vitae.pdf',
    },
  ]

  return (
    <div className='felx-col top-[65%] md:top-[55%] left-0 fixed  md:block'>
      <ul>

        {medios.map(({id, child, style, href, download})=>(
          <li key={id} className='flex justify-between items-center w-40 h-10 px-4 ml-[-100px] hover:ml-[-10px] duration-300 rounded-r-lg bg-blackPrimary'>
            <a href={href} download={download} target='blank' className='flex justify-between items-center w-full text-gray1 font-bold'>
              {child}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Links