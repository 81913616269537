import React from 'react';
import imgPortfolio from '../assets/Projects-img/portfolio.png';
import imgCrmphp from '../assets/Projects-img/crm-php.png';
import imgWebPage from '../assets/Projects-img/web-page.png'
import webDlt from '../assets/Projects-img/web-dlt.png'
import crmDlt from '../assets/Projects-img/crm-dlt.png'
import taskList from '../assets/Projects-img/task-list.png'

const Proyectos = () => {

  const proyecto = [
    {
      id:1,
      src: imgPortfolio,
      title:'My Portfolio',
      demo:'',
    },
    {
      id:2,
      src: imgCrmphp,
      title:'CRM Real State',
    },{
      id:3,
      src: imgWebPage,
      title:'Web Page Company',
      demo:'https://galarza-francisco.github.io/web-Metalurgica-Tenonde/'
    },{
      id:4,
      src: webDlt,
      title:'Web Page Real Estate Company',
      demo:'https://www.dltpropiedades.com.ar',
    },{
      id:5,
      src: crmDlt,
      title:'C R U D',
      demo:'https://gestiondlt.vercel.app/'
    },
    {
      id:6,
      src: taskList,
      title:'People List',
      demo:'https://galarza-francisco.github.io/task-list/'
    },
  ]

  return (
    <div name='Projects' className='md:h-screen w-full bg-gradient-to-b from-blackPrimary to to-blackPrimary text-white pt-8'>  
    <div className='max-w-7xl p-4 mx-auto flex flex-col justify-center w-full h-full'>
      <div className='pt-20 md:py-8'>
        <p className='text-5xl font-bold text-testFluo'>Projects</p>
        <p className='py-6 text-lg md:text-xl font-semibold text-gray1'>Check out some of my work right here!</p>
      </div>
      <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:p-0'>

        {
          proyecto.map(({id, src, demo,title}) => (      
            <div key={id} className='shadow-md shadow-black rounded-lgze'>
              <img src={src} alt="imagenProyecto" className='rounded-md ' />
              <div className='flex items-center justify-center'>
              <a rel='noreferrer' target={'_blank'} href={demo} className=' px-6 m-4 font-bold text-lg md:text-xl text-gray1 duration-200 hover:scale-105 hover:text-testFluo'>{title}</a>
              </div>
            </div>
          ))
        }


      </div>
    </div>

    </div>
  )
}

export default Proyectos