import React from 'react'


const SobreMi = () => {
  return ( 
    <div name='About' className='w-full h-screen bg-gradient-to-b from-black to to-blackPrimary text-white'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div className='pb-2$'>
                <p className='text-4xl font-bold text-testFluo'>About Me</p>
            </div>
            <p className='text-lg md:text-2xl mt-10 text-gray1'>
            Hi! I'm <span className='text-testFluo font-semibold'>Francisco Galarza</span>, Front-end developer with a year of experience in systems, based in Argentina. I enjoy creating, developing and occasionally designing beautiful and intuitive web applications. My academic background is at the Universidad Autónoma de Entre Ríos, where I study a degree in Information Systems. Recently, I completed courses in web development, Javascript, React and Laravel, to improve my programming skills. My goal is to become a Full-Stack developer, with tools and knowledge to solve any kind of problems that arises.
            </p>
        </div>
    </div>
  )
}

export default SobreMi
