import React from 'react'
import BackToTop from '../components/BackToTop';

const Footer = () => {


  return (
    <div className='bg-gradient-to-b from-blackPrimary to to-blackPrimary'>
        <div className=''>
            <BackToTop/>
        </div>
    </div>
  )
}

export default Footer