import React , {useState} from 'react'
import {FaBars, FaTimes} from 'react-icons/fa';
import NavLinks from './NavLinks';
import {HiCode} from 'react-icons/hi'


const NavBar = () => {

    const [nav, setNav] = useState(false);

    const links = [
        {
            id: 1,
            link: 'Home'
        },
        {
            id: 2,
            link: 'About'
        },
        {
            id: 3,
            link: 'Projects'
        },
        {
            id: 4,
            link: 'Skills'
        },

    ]

  return (
    <div className='flex justify-between items-center w-full h-20 px-4 text-gray1 bg-black fixed'>
        <div>
            <h1 className=' text-4xl  text-testFluo'><HiCode/></h1>
        </div>
        <ul className='hidden md:flex'> 
            {links.map(({id, link})=>(
                <NavLinks
                key={id}
                link={link}
                className='px-4 cursor-pointer capitalize font-bold text-fluo-300 hover:scale-110 duration-200 hover:text-testFluo'
                />
            ))}
        </ul>

        <div 
        onClick={()=> setNav(!nav)} 
        className='cursor-pointer pr-4 z-10 text-testFluo md:hidden'
        >
            {nav ? <FaTimes size={25}/> : <FaBars size={25}/>}
        </div>

        {nav && (

        <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-blackPrimary'>
            {links.map(({id, link})=>(
                <NavLinks
                key={id}
                link={link}
                className='px-4 cursor-pointer font-bold hover:scale-110 duration-200 text-gray1 capitalize py-6 text-3xl'
                onClick={()=> setNav(!nav)}
                />
            ))}
        </ul>
        )}

    </div>
  )
}

export default NavBar