import Footer from "./components/Footer";
import Home from "./components/Home";
import Links from "./components/Links";
import NavBar from "./components/NavBar";
import Proyectos from "./components/Proyectos";
import Skills from "./components/Skills";
import SobreMi from "./components/SobreMi";

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Home/>
      <SobreMi/>
      <Proyectos/>
      <Skills />
      <Links/>
      <Footer/>
    </div>
  );
}

export default App;
