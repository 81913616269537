import React from 'react'
import {BiRightArrowAlt} from 'react-icons/bi'
import Foto from '../assets/imagen_1-removebg-preview.png'
import {Link} from 'react-scroll';

const Home = () => {
  return (
    <div name='Home' className='h-screen w-full bg-gradient-to-b from-black via-black to-black'>
        <div className='mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
            <div className='flex flex-col justify-center h-2/5'>
                <h1 className=' text-6xl  sm:text-7xl flex-wrap text-white pr-10 font-extrabold'>Francisco Galarza</h1>
                <p className='text-gray1 text-3xl sm:4xl mt-5 max-w-md font-bold '>Front-End Developer</p>
                <div>
                    <Link to='Projects' smooth duration={500} className='group font-bold w-fit px-6 py-3 my-2 flex items-center rounded-md text-xl bg-testFluo text-black cursor-pointer'>
                        Projects
                        <span className='group-hover:rotate-90 duration-300'>
                            <BiRightArrowAlt size={30} className='ml-1'/>
                        </span>
                    </Link>
                </div>
            </div>

           <div>
            <img src={Foto} alt='mi perfil' className="rounded-full mx-auto w-3/5 mb-10 sm:w-5/4s"/> 
           </div> 
        </div>
    </div>
  )
}

export default Home