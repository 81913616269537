import React from 'react'
import { Link } from 'react-scroll'
import {IoIosArrowUp} from 'react-icons/io'

const BackToTop = () => {
  return (
    <div className='flex justify-end items-center pb-5 pr-16 sticky '>
        <Link to='Home' smooth duration={500} className='text-3xl md:text-5xl p-2 text-testFluo shadow-xl shadow-black rounded-full'><IoIosArrowUp/></Link>
    </div>
  )
}

export default BackToTop